.listStyle {
    width: 100%;
    max-width: 360;
}

.mapContainer {
    width: 100vw;
    height: 40vh;
}

.overflow-x-scroll {
    overflow-x: auto!important;
}

.shopList {
    max-height: 50vh;
    overflow-y: auto;
}

/* TEXT CUSTOMIZATION CLASSES */
.textCenter {
    text-align: center;
}

/* MARGIN CLASSES */


/* PADDING CLASSES */
.padding10 {
    padding: 10px!important;
}

.padding20 {
    padding: 20px!important;
}

.paddingBottom10 {
    padding-bottom: 10px!important;
}
