body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.overflow-x-scroll {
  overflow-x: auto!important;
}

.shopList {
  max-height: 50vh;
  overflow-y: auto;
}

.listStyle {
  width: 100%;
  max-width: 360;
}

.hideOverflow {
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.fullscreen-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 50;
  top: 0;
  left: 0;
}

.flex-center {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-width{
  width: 100%!important;
}

/* TEXT STYLING */
.text-center {
  text-align: center;
}

/* PADDING */
.pb-20 {
  padding-bottom: 20px!important;
}


.flex_vertical_center {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.black {
  color:black;
}

.dim-background {
  background-color: rgba(0, 0, 0, 0.589);
}

.popup-dismiss {
  position: relative;
  top: -40px;
  right: -46%;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: white;
  color:black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pressable {
  cursor: pointer;
}